
import { defineComponent } from 'vue';
import SettingsPassword from '@/views/SettingsPassword'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router';
  
export default defineComponent({
  name : 'AfterLogin',
  components:{
    SettingsPassword
  },
  setup() {
      const router = useRouter()
      const route = useRoute()

      return{
        router,
        route
      }
    },
  methods:{
    async lastStep(){
      await store.dispatch('removeCookieChangePassword')
      this.router.push( { name: 'dashboard', } )
    }
  }
})
